$green: #48b04c;
$green2: #4caf50;

@mixin boxshadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin borderradius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  transform: $value;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
select,
.form-control {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  display: block;
  @include borderradius(4px);
  border: 1px solid #ccc;
  line-height: 1.5;
  background-color: #fff;
}

input,
select,
.form-control {
  &:focus {
    border-color: $green;
    @include boxshadow(
      'inset 0 1px 1px rgba(0, 0, 0, 0.075),  0 0 0 3px rgba($green, 0.1)'
    );
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  @media (max-width: 767px) {
    text-align: center;
  }
}

input.error {
  border-color: red;
}

label {
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
  display: block;
  margin: 0 0 2px 0;
  color: #455362;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

img {
  &:focus {
    outline: none;
  }
}

button {
  background: none;
  border: none;
  outline: none;
  &:active,
  &:focus {
    outline: none;
  }
}

button[type='submit'],
.w-button {
  padding: 0.5rem 2rem;
  border-style: none;
  @include borderradius(5px);
  background-color: $green;
  @include boxshadow(0px 2px 2px rgba(#000, 0.3));
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  line-height: 1.5;

  &:focus {
    outline: none;
  }
  &:hover {
    background: #60c565;
    transition: background-color 0.1s ease-out;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

button[type='submit'] {
  text-transform: uppercase;
  padding: 0.5rem 3.5rem;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.w-form-row {
  margin-bottom: 1rem;
  position: relative;
  .w-remove-button {
    position: absolute;
    padding: 0.5rem;
    bottom: 5px;
    right: 5px;
    img {
      width: 24px;
    }
  }
  @media (max-width: 767px) {
    padding: 1rem;
    border: 1px solid #ccc;
    @include borderradius(5px);
    .w-form-element {
      margin-bottom: 1rem;
    }
    .w-remove-button {
      top: 0;
      bottom: auto;
      right: 5px;
    }
  }
}

.w-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.w-submit-wrap {
  text-align: right;
  margin-top: 2rem;
  @media (max-width: 767px) {
    text-align: center;
  }
}

.w-form-element-um {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > *:last-child {
    flex: 0;
    margin-left: 5px;
  }
}

.u-loader-icon {
  text-align: center;
  img {
    max-width: 280px;
    padding: 50px 0;
    @media (max-width: 1534px) {
      max-width: 100px;
      padding: 20px 0;
    }
  }
}

.w-bg-grey {
  background: #f1f1f1;
}

.w-form {
  max-width: 900px;
  margin: auto;
}

.w-form-inline {
  max-width: none;

  .form-group {
    display: inline-block;
    margin: 0 10px 10px 10px;
    @media (max-width: 767px) {
      display: block;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  label {
    font-size: 12px;
    margin-bottom: 2px;
    display: inline-block;
    margin-right: 0.8rem;
    @media (max-width: 767px) {
      width: 80px;
      text-align: right;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  input,
  select,
  .form-control {
    display: inline-block;
    width: auto;
    @media (max-width: 767px) {
      width: 180px;
    }
  }

  button[type='submit'] {
    line-height: 1.5;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
  }
}

#gmp_logo {
  width: 45px;
  margin-right: 7px;
}

.spacer {
  clear: both;
}

.w-topbar {
  background: #242d3a;
}

.w-navbar {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  a {
    color: inherit;
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      text-decoration: none;
      color: currentColor;
    }
  }
  &-item {
    flex: 0;
    padding: 5px 0.8rem;
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.w-navmobile-trigger {
  @media (min-width: 768px) {
    display: none;
  }
  img {
    width: 30px;
  }
}
.w-logo {
  font-family: 'Montserrat', sans-serif;
  a {
    display: inline-block;
    width: 165px;
  }
  @media (max-width: 767px) {
    flex: 1;
    text-align: center;
  }
}
.w-utils {
  img {
    width: 30px;
  }
}

.w-dropdown {
  position: relative;
  &-toggle {
    &::after {
      content: '';
      display: inline-block;
      border: 5px solid transparent;
      border-top-color: #fff;
      margin-left: 10px;
      vertical-align: middle;
      margin-top: 3px;
    }
  }
  .w-dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 200px;
    z-index: 200;
    > * {
      display: block;
    }
  }
  &:hover {
    .w-dropdown-menu {
      display: block;
    }
  }
}

.w-nav {
  &-item {
    a {
      display: block;
      padding: 0.5rem 1rem;
    }
    .w-dropdown-menu {
      background: #242d3a;
      color: #fff;
      > *:hover {
        background: lighten(#242d3a, 20);
      }
    }
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    flex: 1;
  }
  @media (max-width: 767px) {
    position: absolute;
    top: 55px;
    bottom: 0;
    left: 0;
    background: #242d3a;
    transition: transform 0.5s ease-out;
    width: 300px;
    max-width: 80%;
    z-index: 999;
    &:not(.w-open) {
      @include transform(translateX(-100%));
    }
    &-item {
      display: block;
    }
  }
}

.w-heading-3 {
  font-size: 1.25rem;
  margin: 0;
}

.w-page-title {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  h1 {
    font-size: 2.5rem;
  }
  @media (max-width: 767px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
    h1 {
      font-size: 2rem;
    }
  }
}

.w-title-wrap {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .w-title-button {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 767px) {
    .w-title-button {
      display: inline-block;
      margin-bottom: 1rem;
    }
  }
}

.w-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: currentColor;
      text-decoration: none;
    }
  }

  tr {
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.03);
    }
  }
  td {
    padding: 0.5rem 0.5rem;
    &:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
  thead {
    tr {
      text-align: center;
      border-bottom: 1px solid #ccc;
      background: none;
    }
    th {
      padding: 5px 0.5rem;
      line-height: 1.2;
      font-size: 1.2rem;
      font-weight: 400;
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.ReactTable.w-reacttable {
  font-size: 1rem;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: currentColor;
      text-decoration: none;
    }
  }
  .-pagination {
    @include boxshadow(none);
  }

  .rt-thead.-header {
    @include boxshadow(none);
    font-weight: bold;
  }

  .rt {
    &-tr {
      &:nth-child(2n) {
        background: #f1f1f1;
      }
    }
    &-td {
      padding: 0.5rem 0.5rem;
      line-height: inherit;
      &:first-child {
        text-align: left;
      }
    }
    &-thead .rt {
      &-tr {
        border-bottom: 1px solid #ccc;
        background: none;
      }
      &-th {
        padding: 5px 0.5rem;
        font-size: 1.2rem;
        line-height: 1.2;
        margin: auto;
      }
      &-td {
        white-space: normal;
      }
      &-resizable-header-content {
        white-space: normal;
      }
    }
  }
}

.w-main {
  min-height: calc(100vh - 74px);
}
#footer {
  background: #242d3a;
  color: #fff;
  padding: 20px 1rem 0 1rem;
  height: 74px;

  .footer-content {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .logo_box {
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }

  .logo {
    display: block;
    height: 30px;

    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }
  }
}
